<script setup>
import PrimaryButton from '@/Components/PrimaryButton.vue';
import { useEmitter } from '@/Plugins/Mitt.js';
import { EmitterEvents } from '@/Enums/EmitterEvents.js';

const emitter = useEmitter();

const onClick = () => emitter.emit(EmitterEvents.CTA_MODAL_OPEN, true);
</script>

<template>
  <PrimaryButton @click="onClick">
    {{ $t('Get started') }}
    <svg
      class="ms-2 h-[18px]"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4062 2.74609L16.5938 8.65234C16.7695 8.82812 16.8398 9.03906 16.8398 9.28516C16.8398 9.49609 16.7695 9.70703 16.5938 9.88281L10.4062 15.7891C10.0547 16.1055 9.52734 16.1055 9.21094 15.7539C8.89453 15.4375 8.89453 14.875 9.24609 14.5586L13.9219 10.1289H1.96875C1.47656 10.1289 1.125 9.74219 1.125 9.28516C1.125 8.79297 1.47656 8.44141 1.96875 8.44141H13.9219L9.24609 3.97656C8.89453 3.66016 8.89453 3.09766 9.21094 2.78125C9.52734 2.42969 10.0547 2.42969 10.4062 2.74609Z"
        fill="#191817"
      />
    </svg>
  </PrimaryButton>
  <Teleport to="body"></Teleport>
</template>

<style scoped></style>
