<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { onClientMode } from '@/Plugins/ClientMode.js';

defineProps({
  texts: Array,
});
const splide = ref(null),
  splideInstance = ref(null);

onMounted(() => {
  onClientMode(async () => {
    const { Splide } = await import('@splidejs/splide');
    const { AutoScroll } = await import('@splidejs/splide-extension-auto-scroll');

    splideInstance.value = new Splide(splide.value, {
      direction: 'ttb',
      height: '30rem',
      type: 'loop',
      drag: 'free',
      arrows: false,
      dots: false,
      focus: 'center',
      perPage: 6,
      slideFocus: true,
      rewind: true,
      autoScroll: {
        speed: 0.25,
        autoStart: true,
        rewind: true,
        pauseOnFocus: false,
      },
    });
    splideInstance.value.mount({ AutoScroll });
  });
});

onBeforeUnmount(() => {
  if (splideInstance.value != null) {
    splideInstance.value.destroy(true);
  }
});
</script>

<template>
  <div
    ref="splide"
    class="splide jumbotron-splide"
  >
    <div class="splide__track">
      <ul class="splide__list">
        <template
          v-for="(text, index) in texts"
          :key="`loop_${index}`"
        >
          <li class="splide__slide">{{ text }}</li>
        </template>
      </ul>
    </div>
  </div>
</template>
